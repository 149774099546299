import { useRef, useState, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  GetMessageWithIntl,
  MessageProps,
} from '~/shared/components/parts/Message/Message';
import {
  ModalDialogComponent,
  ModalDialogComponentProps,
} from '~/shared/components/ui/ModalDialog/ModalDialog';
import {
  DataFilterbox,
  DataFilterboxItem,
  Textarea,
  Textbox,
  DateSuggest,
  SimpleListView,
} from '~/shared/components/ui';
import { error } from '../../parts/Toast/Toast';
import { CaptionButton } from '~/shared/components/ui/Button/CaptionButton';
import { Radio, RadioItem } from '~/shared/components/parts/Radio/Radio';
import { ConfirmationDialog } from '~/shared/components/ui/Dialog';
import './base.css';
import './CertificateRegistrationDialog.css';
import {
  includeInputValidateError,
  convertDatetime,
  convertDate,
  autoDownloadFileOnlyName,
  createAttachmentFiles,
  getWorkerExceptionMessage,
} from '~/shared/utils';
import { mtechnavi } from '~/shared/libs/clientsdk';
import { AttachmentFile } from './CommonDialogTypes';
import { getProgramOptionFilterboxData } from '~/shared/utils/converter';
import { validateFreeDate } from '~/shared/components/parts/validator';
import {
  ExtendFileUploader,
  ExtendFileUploaderRef,
  FileItem,
} from '../../file';

export type CertificateRegistrationDisplayModeType = 'decision' | 'display';

export type CertificateRegistrationDialogObtainType = 'yes' | 'no';

export interface CertificateRegistrationDialogMessageOption {
  headerLabelId?: MessageProps;
  decisionLabelId?: MessageProps;
}

export interface CertificateRegistrationDialogInputOption {
  displayModeType: CertificateRegistrationDisplayModeType;
  businessUnitCertificate?: mtechnavi.api.company.IBusinessUnitCertificate;
}

export interface CertificateRegistrationResult {
  businessUnitCertificate: mtechnavi.api.company.IBusinessUnitCertificate;
}

export interface CertificateRegistrationDialogProps {
  isOpen: boolean;
  messageOption: CertificateRegistrationDialogMessageOption;
  inputOption?: CertificateRegistrationDialogInputOption;
  onDecision: (result: CertificateRegistrationResult) => void;
  onCancel: () => void;
  onChangeLoadingState?: (isLoading: boolean) => void;
}

export const CertificateRegistrationDialog = ({
  isOpen,
  messageOption,
  inputOption,
  onDecision,
  onCancel,
  onChangeLoadingState,
}: CertificateRegistrationDialogProps) => {
  const intl = useIntl();
  const [isLoading, setLoading] = useState(false);
  const [isModeTypeDisplay, setModeTypeDisplay] = useState<boolean>(false);
  // 画面項目
  const [certificate, setCertificate] = useState<DataFilterboxItem[]>([]);
  const [obtainedCheck, setObtainedCheck] = useState<string[]>();
  const [displayName, setDisplayName] = useState<string>('');
  const [certificateNumber, setCertificateNumber] = useState<string>('');
  const [attachment, setAttachment] = useState<AttachmentFile>();
  const [obtainedDt, setObtainedDt] = useState<Date | null>(null);
  const [expirationDt, setExpirationDt] = useState<Date | null>(null);
  const radioObtainItemValues: CertificateRegistrationDialogObtainType[] = [
    'yes',
    'no',
  ];
  const [obtain, setObtain] =
    useState<CertificateRegistrationDialogObtainType>();
  const [obtainPlanDt, setObtainPlanDt] = useState<Date | null>(null);
  const [scopeProduct, setScopeProduct] = useState<string>('');
  const [scopeBranch, setScopeBranch] = useState<string>('');
  // 入力制御
  const [isEnableDisplayName, setEnableDisplayName] = useState<boolean>(false);
  const [isDeletableAttachment, setDeletableAttachment] =
    useState<boolean>(true);
  const [isEnableAttachmentDownload, setEnableAttachmentDownload] =
    useState<boolean>(false);
  const [isEnableObtainPlanDt, setEnableObtainPlanDt] =
    useState<boolean>(false);
  // 任意必須制御
  const [isRequiredDisplayName, setRequiredDisplayName] =
    useState<boolean>(false);
  const [isRequiredCertificateNumber, setRequiredCertificateNumber] =
    useState<boolean>(false);
  const [isRequiredObtainedDt, setRequiredObtainedDt] =
    useState<boolean>(false);
  const [isRequiredExpirationDt, setRequiredExpirationDt] =
    useState<boolean>(false);
  const [isRequiredObtainPlanDt, setRequiredObtainPlanDt] =
    useState<boolean>(false);
  // 必須チェック
  const requiredCertificateArea = useRef(null);
  const requiredDisplayNameArea = useRef(null);
  const requiredCertificateNumberArea = useRef(null);
  const requiredObtainedDtArea = useRef(null);
  const requiredExpirationDtArea = useRef(null);
  const requiredObtainPlanDtArea = useRef(null);
  const [workingBlurCertificate, setWorkingBlurCertificate] = useState<Date>();

  // 確認ダイアログ（ファイル添付削除）
  const [isDeleteAttachmentConfirmOpen, setDeleteAttachmentConfirmOpen] =
    useState(false);

  //アップロード処理
  const uploaderRef = useRef<ExtendFileUploaderRef>(null);
  const [isUploadingContain, setUploadingContain] = useState(false);
  const [isErrorContain, setErrorContain] = useState(false);
  const [tempFileList, setTempFileList] = useState<FileItem[]>([]);
  const [isFileUploaderLoading, setFileUploaderLoading] = useState(false);

  // 項目セット
  const [isSuspendObtainedChange, setSuspendObtainedChange] = useState(false);

  // ラジオボタン定義
  const generateItems = () => {
    const items: RadioItem[] = [];
    radioObtainItemValues.map((item) => {
      items.push({
        value: item,
        displayName: GetMessageWithIntl(intl, {
          id: `radio_obtain.${item}`,
          prefixId: 'CertificateRegistrationDialog',
        }),
      });
    });
    return items;
  };
  const radioObtainItems: RadioItem[] = generateItems();

  // フィルタボックス定義
  const CertificateTypeCode = 'A4000002';
  const [certificateTypes] = useMemo<[DataFilterboxItem[]]>(() => {
    const certificateTypes = getProgramOptionFilterboxData(CertificateTypeCode);
    return [certificateTypes];
  }, []);

  // メッセージ定義
  const deleteMessage = {
    id: 'C0000001',
    value: {
      $1: GetMessageWithIntl(intl, { id: 'delete' }),
    },
  };

  // クリア処理
  const clearDisplay = () => {
    setCertificate([]);
    setObtainedCheck(undefined);
    setDisplayName('');
    setCertificateNumber('');
    setAttachment(undefined);
    setObtainedDt(null);
    setExpirationDt(null);
    setEnableObtainPlanDt(true);
    setObtain(undefined);
    setObtainPlanDt(null);
    setScopeProduct('');
    setScopeBranch('');
    setWorkingBlurCertificate(undefined);
    uploaderRef.current?.clear();
  };

  // ファイル添付時処理
  const handleUploaded = (fileList: FileItem[]) => {
    setUploadingContain(fileList.some((item) => item.status === 'uploading'));
    setErrorContain(fileList.some((item) => item.status === 'failure'));
    setTempFileList(fileList);
  };

  // アセット作成処理
  const createAsset = async () => {
    if (isUploadingContain) return;
    if (isErrorContain) {
      error([GetMessageWithIntl(intl, { id: 'E0000103' })]);
      return;
    }
    setLoading(true);
    const category = window.App.services.ui.getNameOptionWithSystemName(
      'A0000016',
      'B03'
    );
    const linkType = window.App.services.ui.getNameOptionWithSystemName(
      'A0000017',
      'B03'
    );
    const files = tempFileList.map((file) => ({
      id: `${file.file.name ?? ''}`,
      category: category.length > 0 ? category[0] : {},
      assetId: '',
      filename: `${file.file.name ?? ''}`,
      url: file.url,
      mimeType: '',
      remarks: '',
      linkType: linkType.length > 0 ? linkType[0] : {},
    }));

    try {
      const tmpData = await createAttachmentFiles(files, 'B07');
      if (tmpData.length > 0) {
        setAttachment(tmpData[0]);
      } else {
        setAttachment(undefined);
      }
    } catch (err) {
      error(getWorkerExceptionMessage(intl, err));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // ファイル添付削除時処理
  const handleDeleteAttachment = () => {
    // 保存行為はしないので、Attachmentのクリア処理
    setAttachment(undefined);
    uploaderRef.current?.clear();
    setDeleteAttachmentConfirmOpen(false);
  };

  // ファイルダウンロード処理
  const handleDownload = async () => {
    autoDownloadFileOnlyName(
      attachment?.filename || '',
      attachment?.assetId || ''
    );
  };

  // キャンセル時処理
  const handleCancel = () => {
    onCancel();
    clearDisplay();
  };

  // 確定時処理
  const handleDecision = () => {
    if (isInputError()) {
      return;
    }
    if (isUploadingContain || isErrorContain) {
      error([GetMessageWithIntl(intl, { id: 'E0000103' })]);
      return;
    }

    const businessUnitCertificate: mtechnavi.api.company.IBusinessUnitCertificate =
      inputOption?.businessUnitCertificate ?? {};
    businessUnitCertificate.certificate =
      window.App.services.ui.getNameOptionWithCode(
        CertificateTypeCode,
        certificate[0].value ?? ''
      );
    if (obtain === 'yes') {
      businessUnitCertificate.obtained = true;
    } else {
      businessUnitCertificate.obtained = false;
    }
    businessUnitCertificate.displayName = displayName;
    businessUnitCertificate.certificateNumber = certificateNumber;
    businessUnitCertificate.attachment = attachment;
    businessUnitCertificate.obtainedDt = convertDatetime(
      obtainedDt,
      'YYYY/MM/DD'
    );
    businessUnitCertificate.expirationDt = convertDatetime(
      expirationDt,
      'YYYY/MM/DD'
    );
    businessUnitCertificate.obtainPlanDt = convertDatetime(
      obtainPlanDt,
      'YYYY/MM/DD'
    );
    businessUnitCertificate.scopeProduct = scopeProduct;
    businessUnitCertificate.scopeBranch = scopeBranch;

    const result: CertificateRegistrationResult = {
      businessUnitCertificate: businessUnitCertificate,
    };

    onDecision(result);
    clearDisplay();
  };

  // エラーチェック
  const isInputError = (): boolean => {
    setWorkingBlurCertificate(new Date());

    const inputValidationCheckList = [
      {
        value: certificate.length > 0 ? certificate[0].value : '',
        ref: requiredCertificateArea,
      },
    ];

    if (isRequiredDisplayName) {
      inputValidationCheckList.push({
        value: displayName ?? '',
        ref: requiredDisplayNameArea,
      });
    }
    if (isRequiredCertificateNumber) {
      inputValidationCheckList.push({
        value: certificateNumber ?? '',
        ref: requiredCertificateNumberArea,
      });
    }
    if (isRequiredObtainedDt) {
      inputValidationCheckList.push({
        value: obtainedDt?.toString() ?? '',
        ref: requiredObtainedDtArea,
      });
    }
    if (isRequiredExpirationDt) {
      inputValidationCheckList.push({
        value: expirationDt?.toString() ?? '',
        ref: requiredExpirationDtArea,
      });
    }
    if (isRequiredObtainPlanDt) {
      inputValidationCheckList.push({
        value: obtainPlanDt?.toString() ?? '',
        ref: requiredObtainPlanDtArea,
      });
    }
    const targetElm = document.querySelector(
      '.certificate-registration-dialog'
    );
    if (includeInputValidateError(targetElm, intl, inputValidationCheckList)) {
      return true;
    }
    return false;
  };

  // 起動モード変更時
  useEffect(() => {
    setModeTypeDisplay((inputOption?.displayModeType ?? '') === 'display');
  }, [inputOption?.displayModeType]);

  // ダイアログ表示変更時
  useEffect(() => {
    // ダイアログ起動時
    if (isOpen) {
      clearDisplay();
      const businessUnitCertificate: mtechnavi.api.company.IBusinessUnitCertificate =
        inputOption?.businessUnitCertificate ?? {};
      if (businessUnitCertificate) {
        // 入力モードではない場合、渡されたデータをそのまま表示する
        // Shareされた側ではcodeがshareされないことも考慮
        if (isModeTypeDisplay) {
          setCertificate([
            {
              value: businessUnitCertificate.certificate?.code ?? '', //空になる場合があるが、表示のみのため許容
              displayName:
                businessUnitCertificate.certificate?.displayNameLang?.ja ?? '',
            },
          ]);
        } else {
          setCertificate(
            certificateTypes.filter(
              (type) => type.value === businessUnitCertificate.certificate?.code
            )
          );
        }

        setSuspendObtainedChange(true);
        setDisplayName(businessUnitCertificate.displayName ?? '');
        setCertificateNumber(businessUnitCertificate.certificateNumber ?? '');
        if (businessUnitCertificate.attachment) {
          const paramAttachment =
            businessUnitCertificate.attachment as AttachmentFile;
          paramAttachment.id = businessUnitCertificate.attachment.assetId ?? '';
          setAttachment(paramAttachment);
          if (businessUnitCertificate.attachment?.assetId) {
            setEnableAttachmentDownload(true);
          } else {
            setEnableAttachmentDownload(false);
          }
        }
        setObtainedDt(convertDate(businessUnitCertificate?.obtainedDt || null));
        setExpirationDt(
          convertDate(businessUnitCertificate?.expirationDt || null)
        );
        setObtain(
          businessUnitCertificate?.businessUnitCertificateId &&
            !businessUnitCertificate?.obtained
            ? 'no'
            : 'yes'
        );
        setObtainPlanDt(
          convertDate(businessUnitCertificate?.obtainPlanDt || null)
        );
        setScopeProduct(businessUnitCertificate.scopeProduct ?? '');
        setScopeBranch(businessUnitCertificate.scopeBranch ?? '');

        // TODO: GPC取引先保守対応(20211216)
        // 取得予定の状況による活性状況・必須条件を設定
        // 次回取得予定なし
        if (
          businessUnitCertificate?.businessUnitCertificateId &&
          !businessUnitCertificate?.obtained
        ) {
          setRequiredObtainPlanDt(true);
          setEnableObtainPlanDt(true);
          setRequiredCertificateNumber(false);
          setRequiredObtainedDt(false);
          setRequiredExpirationDt(false);
        } else {
          // 取得あり
          setRequiredObtainPlanDt(false);
          setEnableObtainPlanDt(false);
          setRequiredCertificateNumber(true);
          setRequiredObtainedDt(true);
          setRequiredExpirationDt(true);
        }
      }
    }
    // isOpen変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // 認証名変更時
  useEffect(() => {
    if (certificate.length > 0) {
      const val = window.App.services.ui.getNameOptionWithCode(
        CertificateTypeCode,
        certificate[0].value ?? ''
      );
      // 認証 その他 の場合
      if (val.systemName === 'B01') {
        setRequiredDisplayName(true);
        setEnableDisplayName(true);
      } else {
        setDisplayName('');
        setRequiredDisplayName(false);
        setEnableDisplayName(false);
      }
    } else {
      setDisplayName('');
      setRequiredDisplayName(false);
      setEnableDisplayName(false);
    }
  }, [certificate]);

  // ファイルアップロード時
  // handleUploadedが複数回実行されているため、直接createAssetをすると不要な呼び出しが実行される
  // 対応策としてtempFileListをの変更を検知してアセットを作成している
  useEffect(() => {
    if (tempFileList.length > 0) {
      createAsset();
    }
    // tempFileList変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempFileList]);

  // 取得予定ラジオボタン変更時
  useEffect(() => {
    if (!obtain) {
      return;
    }

    if (isSuspendObtainedChange) {
      setSuspendObtainedChange(false);
      return;
    }

    // 取得予定
    if (obtain === radioObtainItemValues[1]) {
      setRequiredObtainPlanDt(true);
      setEnableObtainPlanDt(true);
      setRequiredCertificateNumber(false);
      setRequiredObtainedDt(false);
      setRequiredExpirationDt(false);
      setCertificateNumber('');
      setObtainedDt(null);
      setExpirationDt(null);
      setScopeBranch('');
      setScopeProduct('');
      // 取得済み
    } else {
      setRequiredObtainPlanDt(false);
      setEnableObtainPlanDt(false);
      setObtainPlanDt(null);
      setRequiredCertificateNumber(true);
      setRequiredObtainedDt(true);
      setRequiredExpirationDt(true);
    }

    // obtainPlan変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obtain]);

  //Loading制御
  useEffect(() => {
    onChangeLoadingState &&
      onChangeLoadingState(isLoading || isFileUploaderLoading);
    // Loading関連（isLoading/isFileUploaderLoading）変更時だけ起動させたい処理なのでlintから除外させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isFileUploaderLoading]);

  // 確定ラベル表示内容制御
  const decisionLabelId = useMemo(() => {
    const msgId: string[] = [];
    if (messageOption?.decisionLabelId) {
      msgId.push(messageOption.decisionLabelId.prefixId ?? '');
      msgId.push(messageOption.decisionLabelId.viewId ?? '');
      msgId.push(messageOption.decisionLabelId.id ?? '');
    } else {
      msgId.push('decision');
    }
    return msgId.filter((v) => !!v).join('.');
  }, [messageOption?.decisionLabelId]);

  const elements = (
    <div className="certificate-registration-dialog">
      <div className="detail-area">
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-50">
              <div className="input" ref={requiredCertificateArea}>
                <DataFilterbox
                  data={certificateTypes}
                  searchOption={{ targets: 'displayName' }}
                  name="certificate"
                  labelId="CertificateRegistrationDialog.certificate"
                  columns={['certificate']}
                  onChangeState={setCertificate}
                  validateOption={{ required: true }}
                  value={certificate}
                  disabled={isModeTypeDisplay}
                  workingBlur={workingBlurCertificate}
                ></DataFilterbox>
              </div>
            </div>
            <div className="w-25 obtained-checkbox">
              <Radio
                name="obtain"
                value={obtain ?? ''}
                items={radioObtainItems}
                validateOption={{ required: true }}
                onChangeState={(v) => {
                  setObtain(v as CertificateRegistrationDialogObtainType);
                }}
                disabled={isModeTypeDisplay}
              />
            </div>
            <div className="w-25">
              <div className="input" ref={requiredObtainPlanDtArea}>
                <DateSuggest
                  labelId="CertificateRegistrationDialog.obtainPlanDt"
                  name="obtainPlanDt"
                  validator={validateFreeDate(intl)}
                  validateOption={{ required: isRequiredObtainPlanDt }}
                  onChangeState={setObtainPlanDt}
                  value={obtainPlanDt}
                  columns={['obtainPlanDt']}
                  disabled={isModeTypeDisplay || !isEnableObtainPlanDt}
                ></DateSuggest>
              </div>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-50">
              <div className="input" ref={requiredDisplayNameArea}>
                <Textbox
                  name="displayName"
                  labelId="CertificateRegistrationDialog.displayName"
                  type="text"
                  validateOption={{ required: isRequiredDisplayName }}
                  columns={['displayName']}
                  value={displayName}
                  onChangeState={setDisplayName}
                  disabled={isModeTypeDisplay || !isEnableDisplayName}
                />
              </div>
            </div>
            <div className="w-25">
              <div className="input" ref={requiredObtainedDtArea}>
                <DateSuggest
                  labelId="CertificateRegistrationDialog.obtainedDt"
                  name="obtainedDt"
                  validator={validateFreeDate(intl)}
                  validateOption={{ required: isRequiredObtainedDt }}
                  onChangeState={setObtainedDt}
                  value={obtainedDt}
                  columns={['obtainedDt']}
                  disabled={isModeTypeDisplay || isEnableObtainPlanDt}
                ></DateSuggest>
              </div>
            </div>
            <div className="w-25">
              <div className="input" ref={requiredExpirationDtArea}>
                <DateSuggest
                  labelId="CertificateRegistrationDialog.expirationDt"
                  name="expirationDt"
                  validator={validateFreeDate(intl)}
                  validateOption={{ required: isRequiredExpirationDt }}
                  onChangeState={setExpirationDt}
                  value={expirationDt}
                  columns={['expirationDt']}
                  disabled={isModeTypeDisplay || isEnableObtainPlanDt}
                ></DateSuggest>
              </div>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-50">
              <div className="input" ref={requiredCertificateNumberArea}>
                <Textbox
                  name="certificateNumber"
                  labelId="CertificateRegistrationDialog.certificateNumber"
                  type="text"
                  validateOption={{ required: isRequiredCertificateNumber }}
                  columns={['certificateNumber']}
                  value={certificateNumber}
                  onChangeState={setCertificateNumber}
                  disabled={isModeTypeDisplay || isEnableObtainPlanDt}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="input-line attachment-area">
          <div className="item-group-100">
            <div className="w-50">
              <SimpleListView
                data={attachment?.assetId ? [attachment] : []}
                actionOptions={{
                  onRowClick: handleDownload,
                  onDelete: () => {
                    setDeleteAttachmentConfirmOpen(true);
                  },
                }}
                viewOptions={{
                  omitFooter: true,
                  previewRowCount: 1,
                  keyColumn: '',
                  columns: [
                    {
                      header: {
                        prefixId: 'certificateRegistrationDialog',
                        id: 'attachment.filename',
                      },
                      propertyName: 'filename',
                    },
                  ],
                  readonly:
                    isModeTypeDisplay || isLoading || isFileUploaderLoading,
                }}
              />
            </div>
            <div className="w-25">
              {!isModeTypeDisplay && (
                <div className="upload-button">
                  <ExtendFileUploader
                    name="fileUploader"
                    ref={uploaderRef}
                    dndOption={{
                      enabled: false,
                    }}
                    multiple={false}
                    validateOption={{
                      maxFileSizeInMebis: 50,
                    }}
                    resultOption={{
                      previewRowCount: 0,
                      omitFooter: true,
                    }}
                    onUpload={handleUploaded}
                    onChangeLoadingState={(v) => setFileUploaderLoading(v)}
                    disabled={isEnableObtainPlanDt}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <div className="input">
                <Textarea
                  name="scopeProduct"
                  labelId="CertificateRegistrationDialog.scopeProduct"
                  className="w-100 mh-normal"
                  columns={['scopeProduct']}
                  value={scopeProduct}
                  validateOption={{ maxLength: 500 }}
                  onChangeState={setScopeProduct}
                  disabled={isModeTypeDisplay || isEnableObtainPlanDt}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="input-line">
          <div className="item-group-100">
            <div className="w-100">
              <div className="input">
                <Textarea
                  name="scopeBranch"
                  labelId="CertificateRegistrationDialog.scopeBranch"
                  className="w-100 mh-normal"
                  columns={['scopeBranch']}
                  value={scopeBranch}
                  validateOption={{ maxLength: 500 }}
                  onChangeState={setScopeBranch}
                  disabled={isModeTypeDisplay || isEnableObtainPlanDt}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="button-area">
          <CaptionButton
            name="cancelBtn"
            buttonType="cancel"
            className="button"
            caption={GetMessageWithIntl(intl, { id: 'cancel' })}
            onClick={() => handleCancel()}
          />
          {!isModeTypeDisplay && (
            <CaptionButton
              name="decisionBtn"
              buttonType="basic"
              className="button"
              caption={GetMessageWithIntl(intl, { id: decisionLabelId })}
              onClick={handleDecision}
              disabled={isLoading || isFileUploaderLoading}
            />
          )}
        </div>
      </div>
    </div>
  );

  const openModalProps: ModalDialogComponentProps = {
    cancel: () => {
      handleCancel();
    },
    send: () => {},
    modalIsOpen: isOpen,
    headerLabelId:
      messageOption.headerLabelId ?? inputOption?.displayModeType === 'display'
        ? {
            prefixId: 'DIALOG_TITLE',
            id: 'certificateConfirmation',
          }
        : {
            prefixId: 'DIALOG_TITLE',
            id: 'certificateRegistration',
          },
    messageLabelId: {},
    elements,
  };

  return (
    <>
      <div className="CertificateRegistrationDialog">
        <ModalDialogComponent {...openModalProps} />
      </div>
      {/* 削除 確認ダイアログ */}
      <ConfirmationDialog
        isOpen={isDeleteAttachmentConfirmOpen}
        viewMessage={deleteMessage}
        onDecision={handleDeleteAttachment}
        onCancel={() => {
          setDeleteAttachmentConfirmOpen(false);
        }}
      />
    </>
  );
};
